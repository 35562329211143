import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

export const Redirect = ({
  link,
  title,
  className,
}: {
  link: string
  title: string
  className: string
}) => {
  const [isRelativeLink, setIsRelativeLink] = useState(false)

  useEffect(() => {
    setIsRelativeLink(
      !(link.indexOf('https://') === 0 || link.indexOf('http://') === 0)
    )
  }, [link])

  const parseLink = () => {
    if (isRelativeLink) {
      return (
        <Link className={className} to={link}>
          {title}
        </Link>
      )
    }

    return (
      <a
        className={className}
        href={link}
        target="_blank"
        rel="noopener nofollow"
      >
        {title}
      </a>
    )
  }
  return parseLink()
}

export default Redirect
