// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-previews-tsx": () => import("./../src/pages/previews.tsx" /* webpackChunkName: "component---src-pages-previews-tsx" */),
  "component---src-pages-the-tia-whole-health-exam-tsx": () => import("./../src/pages/the-tia-whole-health-exam.tsx" /* webpackChunkName: "component---src-pages-the-tia-whole-health-exam-tsx" */),
  "component---src-templates-appointment-tsx": () => import("./../src/templates/appointment.tsx" /* webpackChunkName: "component---src-templates-appointment-tsx" */),
  "component---src-templates-editorial-tsx": () => import("./../src/templates/editorial.tsx" /* webpackChunkName: "component---src-templates-editorial-tsx" */),
  "component---src-templates-journal-tsx": () => import("./../src/templates/journal.tsx" /* webpackChunkName: "component---src-templates-journal-tsx" */),
  "component---src-templates-location-tsx": () => import("./../src/templates/location.tsx" /* webpackChunkName: "component---src-templates-location-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-service-tsx": () => import("./../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-team-tsx": () => import("./../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */)
}

