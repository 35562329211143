export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), 600)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 600)
  }
  return false
}

// Commenting this since it's causing a page to break

// export const onRouteUpdate = ({ location, prevLocation }) => {
//   reloadOTBanner()
// }

// Commenting this since we are not using this anywhere for now
//
// export const wrapRootElement = ({ element }) => (
//   <AmplitudeExperimentProvider>{element}</AmplitudeExperimentProvider>
// )
