import React, { FC } from 'react'
// @ts-ignore
import CatastrophicErrorImg from '../images/catastrophic-error.svg'

export const ErrorComponent: FC<Record<string, never>> = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '12px',
      }}
    >
      <div style={{ maxWidth: '480px', width: '100%' }}>
        <img src={CatastrophicErrorImg} alt="Tia Error" />
      </div>
      <p style={{ fontSize: '16px' }}>
        Something went wrong, please try again. We've notified our team about
        it.
      </p>
    </div>
  )
}
