import { Link } from 'gatsby'
import cx from 'classnames'
import React, { useState, useEffect } from 'react'
import { ampli } from '../ampli'

import { Logo, Caret, JoinShape } from 'src/components/svgs'
import { RelativeLink } from '../interfaces/HeaderNavbar'
import { GlobalBannerProps } from 'src/interfaces/GlobalBanner'
import classes from './header.module.css'

export const Header = (props: {
  headerNavProps: RelativeLink[]
  globalBannerProps: GlobalBannerProps
  location: string
}) => {
  const [dropDown, setDropDown] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [relativeLinks, setRelativeLinks] = useState<RelativeLink[]>([])
  const [globalBannerProps, setGlobalBannerProps] = useState<
    GlobalBannerProps
  >()

  useEffect(() => {
    setRelativeLinks(props.headerNavProps)
  }, [props.headerNavProps])

  useEffect(() => {
    setGlobalBannerProps(props.globalBannerProps)
  }, [props.globalBannerProps])

  const closeAll = () => {
    setIsOpen(false)
    setDropDown(false)
  }

  return (
    <React.Fragment>
      <div className={classes.headerContainer}>
        {props.location?.includes('summer-of-self') &&
          globalBannerProps &&
          Object.keys(globalBannerProps).length === 3 && (
            <picture className={classes.banner}>
              <source
                media="(max-width:528px)"
                srcSet={globalBannerProps.mobileBanner.asset.url}
              />
              <source
                media="(max-width:834px)"
                srcSet={globalBannerProps.tabletBanner.asset.url}
              />
              <img
                src={globalBannerProps.desktopBanner.asset.url}
                alt="Banner"
                className={classes.banner}
              />
            </picture>
          )}
        <header className="header x bcb f aic top z8 left">
          <div className="container--l px1 py05 outer header__inner mxa x">
            <div className="f jcb aic x">
              <div className="f jcs aie rel">
                <Link
                  aria-label="navigate to homepage"
                  onClick={() => closeAll()}
                  className="block header__logo-wrap"
                  to="/"
                >
                  <Logo className="header__logo block" />
                </Link>
                <button
                  onMouseOver={() => setDropDown(false)}
                  className="s35 dropdown__button no-style ml1 rel header__care cw bcb"
                >
                  <span>
                    Care <Caret />
                  </span>
                  <ul
                    className={cx('dropdown__menu m0 p0 py05 abs bcb top', {
                      'no-events': dropDown,
                    })}
                  >
                    {relativeLinks?.map((link: RelativeLink) => {
                      return (
                        <li key={link._key} className="m0 p0">
                          <Link
                            onClick={() => setDropDown(true)}
                            className="caps mono ss16  m0 p0 cw"
                            to={link.link}
                          >
                            {link.title}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </button>
              </div>
              <div className="header__desktop-links f jcb aic">
                <Link
                  onClick={() => setDropDown(false)}
                  to="/membership"
                  className="caps mono cw"
                >
                  How Tia Works
                </Link>
                <a
                  onClick={() => {
                    ampli.joinNowButtonClicked()
                    setDropDown(false)
                  }}
                  href={process.env.GATSBY_WIDGET_URL}
                  className="button--join jcc aic f rel"
                >
                  <span className="z1 rel">Join Tia</span>
                </a>
                <a
                  href={`${process.env.GATSBY_WIDGET_URL?.split('/widget')[0]}`}
                  className="cw caps mono"
                >
                  Log in
                </a>
              </div>
              <div className="jcs f aic headerJoinWrapper showMobile">
                <a
                  onClick={() => ampli.joinNowButtonClicked()}
                  href={process.env.GATSBY_WIDGET_URL}
                  className="headerJoin"
                >
                  <span>Join Tia</span>
                </a>
              </div>
              <div className="header__mobile-toggle">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="caps mono h5 w100 block no-style cw bcb"
                >
                  {isOpen ? 'Close' : 'Menu'}
                </button>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div
        className={cx('header__mobile fix bcb z7 x y top', isOpen && 'is-open')}
      >
        <div
          className="header__mobile-inner p1"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="f jcs mt1 pt1 aic header__mobile-footer">
            <a
              href={`${process.env.GATSBY_WIDGET_URL?.split('/widget')[0]}`}
              className="cw bold basis mr1 cpink h3"
            >
              Log in
            </a>
          </div>
          <ul className="m0">
            <li className="m0 p0">
              <Link className=" h2  basis m0 p0 cw" to="/membership">
                Membership
              </Link>
            </li>
            <li className="m0 p0">
              <Link className=" h2  basis m0 p0 cw" to="/locations">
                Locations
              </Link>
            </li>
            <li className="m0 p0">
              <Link className=" h2  basis m0 p0 cw" to="/services">
                Services
              </Link>
            </li>
            <li className="m0 p0">
              <Link className=" h2  basis m0 p0 cw" to="/care-team">
                Care Team
              </Link>
            </li>
            <li className="m0 p0">
              <Link className=" h2  basis m0 p0 cw" to="/insurance">
                Insurance
              </Link>
            </li>
          </ul>
          <div className="mt1 pt1">
            <Link
              onClick={() => setDropDown(false)}
              to="/tiatake"
              className=" block basis h3 mb10 cw"
            >
              The Tia Take
            </Link>
            <Link
              onClick={() => setDropDown(false)}
              to="/partners"
              className=" block basis h3  cw"
            >
              Partners
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
