import SumoLogger from 'sumo-logger'
import type { SumoLoggerOptions } from 'sumo-logger'

export class Sumo {
  private logger: SumoLogger

  constructor(opts: SumoLoggerOptions) {
    this.logger = new SumoLogger(<SumoLoggerOptions>{
      returnPromise: true,
      interval: 0, // send messages iimediately
      batchSize: 0,
      sendErrors: true,
      graphite: false,
      ...opts,
    })
  }

  private async _log(level: string, ...args: any[]) {
    await this.logger.log({
      level,
      messages: [...args],
    })
  }

  async log(...msgs: any[]) {
    await this._log('log', ...msgs)
  }
  async trace(...msgs: any[]) {
    await this._log('trace', ...msgs)
  }
  async debug(...msgs: any[]) {
    await this._log('debug', ...msgs)
  }
  async info(...msgs: any[]) {
    await this._log('info', ...msgs)
  }
  async warn(...msgs: any[]) {
    await this._log('warn', ...msgs)
  }
  async error(...msgs: any[]) {
    await this._log('error', ...msgs)
  }
  async fatal(...msgs: any[]) {
    await this._log('fatal', ...msgs)
  }
}
