import { Sumo } from './sumo'

let logger: any

if (process.env.GATSBY_SUMO_LOG_ENDPOINT) {
  console.info('Loading SUMO logger...')
  logger = new Sumo({
    endpoint: process.env.GATSBY_SUMO_LOG_ENDPOINT,
    sourceName: `growth-portal-${
      process.env.GATSBY_ENVIRONMENT || 'development'
    }`,
    sourceCategory: 'App Logs',
  })
} else {
  console.info('Loading Simple logger...')
  logger = {
    log: console.log,
    trace: console.trace,
    debug: console.debug,
    info: console.info,
    warn: console.warn,
    error: console.error,
    fatal: console.error,
  }
  console.warn('Sumo Logic endpoint url is not provided')
}

export default logger
