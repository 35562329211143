/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web-public'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 217
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/asktia/Asktia/implementation/web-public)
 */

import * as amplitude from '@amplitude/analytics-browser'

export type Environment = 'production' | 'development'

export const ApiKey: Record<Environment, string> = {
  production: 'd2c588dc1f8a91987d7540e4521eef21',
  development: '9018a17347d3e4c10774fd74f8eaef99',
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '217',
    branch: 'main',
    source: 'web-public',
    versionId: '97dac785-0469-48d1-a2d2-922bdd48fa44',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
}

export interface LoadOptionsBase {
  disabled?: boolean
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment
  client?: { configuration?: BrowserOptions }
}
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions }
}
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient }
}

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance

export interface IdentifyProperties {
  /**
   * If this is an active member or not. Set this as active when a user's membership payment is verified, and inactive after cancellation.
   */
  activeMembership?: string
  /**
   * Tells us appts completed per month by user over 12 months
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  appointment_frequency?: number
  /**
   * Tells us the total appointments completed by the user
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  appointment_volume?: number
  /**
   * Set the arousal result from the stress quiz.
   */
  arousal?: string
  /**
   * Contains the last 4 digits of the latest card on file for a member. This is updated whenever a member updates their card on file
   */
  cardLast4?: string
  dashboard_group?: string
  /**
   * Stores the remaining deductible amount of the patient's insurance plan whenever we receive this as part of the eligibility response.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  deductibleRemaining?: number
  /**
   * Email
   */
  email?: string
  /**
   * Date of when user created their account.
   */
  emailCreationDate?: string
  /**
   * Set the energy result from the stress quiz.
   */
  energy?: string
  /**
   * UUID/GUID
   */
  externalId?: string
  /**
   * Tells us the first IRL clinic the user visited
   */
  first_irl_clinic?: string
  /**
   * First Name
   */
  first_name?: string
  /**
   * Name of current clinic location. This is the same as 'originatingClinicLocation' unless updated in Settings or elsewhere in the experience.
   */
  homeLocation?: string
  initial_referrer?: string
  initial_referring_domain?: string
  initial_utm_campaign?: string
  initial_utm_content?: string
  initial_utm_id?: string
  initial_utm_medium?: string
  initial_utm_source?: string
  initial_utm_term?: string
  insuranceEligibilityStatus?: string
  insuranceNetworkStatus?: string
  /**
   * Whether or not the user has insurance on file.
   */
  insuranceOnFile?: boolean
  /**
   * The insurance status of the user, whether it is 'eligible' | 'ineligible' | 'unsure'.
   */
  insuranceStatus?: string
  /**
   * Tells us the most recent IRL clinic the user visited
   */
  last_irl_clinic?: string
  /**
   * Last Name
   */
  last_name?: string
  /**
   * last time meber completed annual preventative exam
   */
  lastCompletedAnnualPreventativeExam?: string
  /**
   * last time someone completed whole health exam in clinic
   */
  lastCompletedTiaWholeHealthInClinicCare?: string
  mailingAddressCity?: string
  mailingAddressLine1?: string
  mailingAddressLine2?: string
  mailingAddressPostalCode?: string
  mailingAddressState?: string
  /**
   * Tells us the date the user's membership was deactivated
   */
  member_end_date?: string
  /**
   * The status of the membership, either active, frozen or cancelled.
   */
  membership?: string
  /**
   * Date member originally signed up for their Tia account.
   */
  memberSignUpDate?: string
  /**
   * Tells us the date of the user's next schedule annual (tia_whole_health_in_clinic_care)
   */
  next_scheduled_tia_whole_health_in_clinic_care?: string
  /**
   * Location where user originally signed up.
   */
  originatingClinicLocation?: string
  /**
   * Tracks the user's latest payment method brand
   */
  paymentMethodBrand?: string
  /**
   * Phone Number
   */
  phone?: string
  /**
   * What pricing model the user is under currently.
   */
  pricingModel?: string
  /**
   * The promo code that was used during registration.
   */
  promoCodeUsed?: string
  /**
   * The referral code the user used initially during sign up, if available.
   */
  referralCode?: string
  /**
   * The last page the user was on (for example, https://amplitude.com/behavioral-analytics-platform?ref=nav)
   */
  referrer?: string
  /**
   * The domain that the user was last on (for example, https://amplitude.com)
   */
  referring_domain?: string
  /**
   * The date the membership is due for renewal
   */
  renewalTimestamp?: string
  /**
   * The cookie ID or session ID.
   */
  sessionId?: string
  show_appointments_page_group?: string
  twhe_1_goals_group?: string
  twhe_onboarding_group?: string
  userFromWebinar?: boolean
  /**
   * If this property is set, it means this user was created because they took part of a CDP event, like zoom, intercom, braze, etc.
   */
  userOriginatedFrom?: string
  using_www_for_member_creation?: boolean
  usingMemberService?: boolean
  /**
   * This identifies a specific campaign used (for example, "summer_sale")
   */
  utm_campaign?: string
  /**
   * This identifies what brought the user to the site and is commonly used for A/B testing (for example, "banner-link", "text-link")
   */
  utm_content?: string
  utm_id?: string
  utm_medium?: string
  /**
   * This identifies which website sent the traffic (for example, Google, Facebook)
   */
  utm_source?: string
  /**
   * This identifies paid search terms used (for example, product+analytics)
   */
  utm_term?: string
  /**
   * The UTM Campaign Code.
   */
  utmCampaign?: string
  /**
   * The UTM Source code.
   */
  utmSource?: string
}

export interface EmailCapturedProperties {
  /**
   * Set the email as the user identifier, we will call an alias call when they verify on the website later and merge with the email.
   */
  email?: string
  /**
   * Where the email was captured, email newsletter, stress quiz, etc.
   *
   * Examples:
   * Profile, Sign Up
   */
  location?: string
}

export interface EventRsvpProperties {
  /**
   * The date and time of the event
   */
  eventDateAndTime?: string
  /**
   * The event URL that is passed. ex. eventbrite.com...
   */
  eventUrl?: string
}

export interface PageViewedProperties {
  initial_referrer?: any
  initial_referring_domain?: any
  /**
   * Name of the page the user is on.
   *
   * Examples:
   * Home, Home - Booked, Search, Service-MH, Service-GYN, Service-PC,Service-ACU, Pre Question, Time + Date Selection, Post Question, Review Appointment, Book Transition, Complete Intake, Review Intake, Complete Health Record, Review Health Record, Booked-Prepare, Booked - Check In Now, Checked In - Virtual Not Open Yet, Checked In - Virtual Appt Open now, Your Care, Settings, Your Appointments, Health Record, Inbox Home, Care Coordinator, CWCT - App Scroll, Tracking Daily, Cancel Apt 1.101, Reschedule Apt 1.42, Cancel 1.96
   */
  page?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string
  /**
   * Grab the URL that the user is on.
   *
   * Examples:
   * Join, Homepage, etc.
   */
  url?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  utm_campaign?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  utm_content?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  utm_medium?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  utm_source?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  utm_term?: string
  /**
   * Set as a Super Property via mixpanel.register
   */
  utmCampaign?: string
  /**
   * Set as a Super Property via mixpanel.register
   */
  utmContent?: string
  /**
   * Set as a Super Property via mixpanel.register
   */
  utmMedium?: string
  /**
   * Set as a Super Property via mixpanel.register
   */
  utmSource?: string
  /**
   * Set as a Super Property via mixpanel.register
   */
  utmTerm?: string
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties
  }
}

export class EmailCaptured implements BaseEvent {
  event_type = 'Email Captured'

  constructor(public event_properties?: EmailCapturedProperties) {
    this.event_properties = event_properties
  }
}

export class EventRsvp implements BaseEvent {
  event_type = 'Event Rsvp'

  constructor(public event_properties?: EventRsvpProperties) {
    this.event_properties = event_properties
  }
}

export class JoinNowButtonClicked implements BaseEvent {
  event_type = 'Join Now Button Clicked'
}

export class JoinNowHeroButtonClicked implements BaseEvent {
  event_type = 'Join Now Hero Button Clicked'
}

export class PageViewed implements BaseEvent {
  event_type = 'Page Viewed'

  constructor(public event_properties?: PageViewedProperties) {
    this.event_properties = event_properties
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> }

const getVoidPromiseResult = () => ({ promise: Promise.resolve() })

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      console.log('is amplitude being initialized')
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.log('how about meeeee')
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Email Captured
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Email%20Captured)
   *
   * When email is captured, but a user is not signed up. This event can be used for landing pages like the Stress Quiz to set the user email with a UUID being set.
   *
   *
   * In the Membership Signup: [Github Link](https://github.com/AskTia/www/blob/0cb2be0c32e5b3a70fe6412c4449815080b9636a/controllers/account-controller.js#L137)
   *
   * In Stress Quiz: (Eric doesn't know where this lives)
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  emailCaptured(
    properties?: EmailCapturedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EmailCaptured(properties), options);
  }

  /**
   * Event Rsvp
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Event%20Rsvp)
   *
   * When a user RSVP for an event on the events page
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. eventDateAndTime)
   * @param options Amplitude event options.
   */
  eventRsvp(
    properties?: EventRsvpProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventRsvp(properties), options);
  }

  /**
   * Join Now Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Join%20Now%20Button%20Clicked)
   *
   * When the user clicks the Join Now button *in the global nav bar* of asktia.com begin the sign up process. This event does not fire for other Join Now buttons on the marketing site
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  joinNowButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new JoinNowButtonClicked(), options);
  }

  /**
   * Join Now Hero Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Join%20Now%20Hero%20Button%20Clicked)
   *
   * This event tracks when a user clicks on the "Join Now" button in the hero section of the /join/join-tia page on the marketing website
   *
   * \-
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  joinNowHeroButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new JoinNowHeroButtonClicked(), options);
  }

  /**
   * Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Page%20Viewed)
   *
   * This event fires when a user views any webpage on the marketing site. We plan to deprecate this event in favor of the default Amplitude page event. This custom event appears to have been instrumented during Tia's transition from Mixpanel to Amplitude.
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. initial_referrer)
   * @param options Amplitude event options.
   */
  pageViewed(
    properties?: PageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageViewed(properties), options);
  }
}

export const ampli = new Ampli()

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions

export type BrowserClient = amplitude.Types.BrowserClient
export type BaseEvent = amplitude.Types.BaseEvent
export type IdentifyEvent = amplitude.Types.IdentifyEvent
export type GroupEvent = amplitude.Types.GroupIdentifyEvent
export type Event = amplitude.Types.Event
export type EventOptions = amplitude.Types.EventOptions
export type Result = amplitude.Types.Result
