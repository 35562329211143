import React, { useEffect, useState } from 'react'

import { Newsletter } from 'src/components/newsletter'

import { FooterProps, FooterLink, Icon } from 'src/interfaces/Footer'
import Redirect from './global/redirect'

const date = new Date()

export const Footer = (props: { footerProps: FooterProps }) => {
  const [leftLinks, setLeftLinks] = useState<FooterLink[]>([])
  const [bottomLinks, setBottomLinks] = useState<FooterLink[]>([])
  const [icons, setIcons] = useState<Icon[]>([])

  useEffect(() => {
    setLeftLinks(props.footerProps.leftLinks)
    setBottomLinks(props.footerProps.bottomLinks)
    setIcons(props.footerProps.icons)
  }, [])

  return (
    <footer className="x footer rel z3 bcraspberry cw">
      <div className="footer__main container--l mxa p1 outer f jcb ais fw">
        <div className="footer__main-flex x jcb ais fw f">
          <div className="footer__main-links x f ais">
            {leftLinks.map((leftLink: FooterLink) => (
              <Redirect
                key={leftLink._key}
                className="caps mono ss14 sm16"
                title={leftLink.title}
                link={leftLink.link}
              />
            ))}
          </div>
          <div className="footer__downloads f jcb aic x">
            <div className="footer__social f jcb aic">
              {icons.map((icon: Icon) => (
                <a
                  key={icon._key}
                  href={icon.link}
                  rel="noopener nofollow"
                  target="_blank"
                  className="f jcc aic mr1"
                >
                  <img
                    width="20"
                    height="20"
                    src={icon.footerIcon.asset.url}
                    alt={icon.footerIcon.asset.originalFilename}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="footer__credits f jcb ais mt1 x">
          <div className="f fw jcs footer__credits-inner">
            {bottomLinks.map((bottomLink: FooterLink) => (
              <Redirect
                key={bottomLink._key}
                className="x mb1 ss12 sm15 mr1"
                title={bottomLink.title}
                link={bottomLink.link}
              />
            ))}
          </div>
          <div className="ss12 sm15 footer__copy f">
            &copy; Tia {date.getFullYear()}
          </div>
        </div>
      </div>
    </footer>
  )
}
